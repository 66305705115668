import React from "react";
import Header from "../../components/default/Header";
import Tittle from "../../components/default/Tittle";
import Container from "../../components/default/Container";
import Content from "../../components/default/Content";
import ContentEntitie from "../../components/ContainerEntitie/ContentEntitie";

const Main = () => {
  return (
    <div>
      <Header />
      <Container>
        <Content>
          <Tittle text="Selecione a Entidade para ser Gerado a View" />
          <ContentEntitie />
        </Content>
      </Container>
    </div>
  );
};

export default Main;
