import React from "react";
import Title from "../../default/Tittle";
import styles from "./style.module.css";

const ContentCheckView = ({ checked, setChecked }) => {
  const [button, setButton] = React.useState();

  function handleChangeCheck({ target }) {
    if (target.checked) {
      setChecked([...checked, target.value]);
    } else {
      setChecked(checked.filter((check) => check !== target.value));
    }
  }

  function handleChecked(check) {
    return checked.includes(check);
  }

  const handleClick = () => {
    setButton(checked);

    console.log(checked);
  };

  return (
    <form onSubmit={(event) => event.preventDefault()}>
      <Title text="Marque as tabelas para serem adicionadas na view:" />
      <br />

      <label className={styles.containerCheckbox}>
        <input
          type="checkbox"
          value="name"
          checked={handleChecked("name")}
          onChange={handleChangeCheck}
          className={styles.itemsArray}
        />
        <span className={styles.checkmark}></span>
        Name
      </label>

      <label className={styles.containerCheckbox}>
        <input
          type="checkbox"
          value="lastname"
          checked={handleChecked("lastname")}
          onChange={handleChangeCheck}
          className={styles.itemsArray}
        />
        <span className={styles.checkmark}></span>
        Lastname
      </label>

      <label className={styles.containerCheckbox}>
        <input
          type="checkbox"
          value="email"
          checked={handleChecked("email")}
          onChange={handleChangeCheck}
          className={styles.itemsArray}
        />
        <span className={styles.checkmark}></span>
        Email
      </label>

      <label className={styles.containerCheckbox}>
        <input
          type="checkbox"
          value="phone"
          checked={handleChecked("phone")}
          onChange={handleChangeCheck}
          className={styles.itemsArray}
        />
        <span className={styles.checkmark}></span>
        Telefone
      </label>

      <label className={styles.containerCheckbox}>
        <input
          type="checkbox"
          value="contact_id"
          checked={handleChecked("contact_id")}
          onChange={handleChangeCheck}
          className={styles.containerCheckbox}
        />
        <span className={styles.checkmark}></span>
        Contact_ID
      </label>

      <br />
      <button onClick={handleClick} className={styles.button}>
        Gerar View
      </button>
    </form>
  );
};

export default ContentCheckView;
