import React from "react";
import styles from "./style.module.css";
// import logoBytebio from "../../../assets/header/bytebio.png";
import logoBytebioWhite from "../../../assets/header/logo-white.png";

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <a href="/" className={styles.link}>
          <h2 className={styles.logoExtractView}>ExtractView</h2>
        </a>
        <img
          src={logoBytebioWhite}
          alt="Bytebio"
          className={styles.logoBytebio}
        />
      </div>
    </header>
  );
};

export default Header;
