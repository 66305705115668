import React from "react";
import Main from "./pages/main";
import CreateView from "./pages/CreateView";
import UpdateView from "./pages/UpdateView";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="create" element={<CreateView />} />
        <Route path="update" element={<UpdateView />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
