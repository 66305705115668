import React from "react";
import styles from "./style.module.css";
import api from "../../../services/api";
import ContentCheckView from "../ContentCheckView";

const FormView = (props) => {
  // States
  const [select, setSelect] = React.useState("lead");
  const [checked, setChecked] = React.useState([]);
  const [data, setData] = React.useState();
  const [resData, setResData] = React.useState();

  // Effects
  React.useEffect(() => {
    api
      .post("ENDPOINT_GABRIEL", data)
      .then((response) => setResData(response.data))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, [data]);

  const handleClickEntitie = () => {
    setData(select);
  };

  return (
    <>
      <form
        className={styles.content}
        onSubmit={(event) => event.preventDefault()}
      >
        <select
          value={select}
          onChange={({ target }) => setSelect(target.value)}
          className={styles.select}
        >
          <option value="lead">Lead</option>
          <option value="contact">Contact</option>
          <option value="deal">Deal</option>
          <option value="company">Company</option>
        </select>

        <button onClick={handleClickEntitie} className={styles.button}>
          Buscar
        </button>
      </form>

      <br />
      <hr />
      <br />
      <br />

      {!resData ? (
        <ContentCheckView checked={checked} setChecked={setChecked} />
      ) : (
        ""
      )}
    </>
  );
};

export default FormView;
