import React from "react";
import Header from "../../components/default/Header";
import Tittle from "../../components/default/Tittle";
import Container from "../../components/default/Container";
import Content from "../../components/default/Content";
import styles from "./style.module.css";
import { Link } from "react-router-dom";

const Button = (props) => {
  return <button className={styles.button}>{props.text}</button>;
};

const Main = () => {
  return (
    <div>
      <Header />
      <Container>
        <Content>
          <Tittle text="O que você precisa?" />

          <Link to="/create">
            <Button text="Gerar View" />
          </Link>

          <Link to="/update">
            <Button text="Editar View" />
          </Link>
        </Content>
      </Container>
    </div>
  );
};

export default Main;
